.description {
	&__row {
		position: relative;
		display: flex;
		align-items: center;
		min-height: 624px;
		@include d {
			min-height: 550px; }
		@include t {
			min-height: 450px; }
		@include m {
			display: block;
			min-height: auto; }
		&:first-child {
			justify-content: flex-end; }
		@include nl {
			margin-bottom: 133px;
			@include d {
				margin-bottom: 112px; }
			@include t {
				margin-bottom: 80px; }
			@include m {
				margin-bottom: 48px; } } }
	&__row:first-child &__bg {
		top: 56%;
		left: calc(50% - 721px);
		@include d {
			left: calc(50% - 580px);
			width: 750px; }
		@include t {
			left: calc(50% - 490px);
			width: 650px; }
		@include m {
			top: auto;
			left: auto;
			width: auto;
			margin: 50px -90px 0 -90px;
			text-align: center; } }
	&__row:first-child &__preview {
		&:nth-child(2) {
			top: 27%;
			left: 10%;
			width: 33%;
			&.aos-animate {
				animation: up-down 4s 1.2s infinite; } }
		&:nth-child(3) {
			top: 15%;
			right: 17%;
			width: 37%;
			&.aos-animate {
				animation: up-down 4s 1.8s infinite; } } }
	&__row:nth-child(2) &__bg {
		top: 55%;
		right: calc(50% - 720px);
		@include d {
			right: calc(50% - 600px);
			width: 750px; }
		@include t {
			right: calc(50% - 550px);
			width: 650px; }
		@include m {
			top: auto;
			right: auto;
			width: auto;
			margin: 48px -90px 0 -60px; } }
	&__row:nth-child(2) &__preview {
		&:nth-child(2) {
			top: -8%;
			left: 13%;
			width: 34%; } }
	&__wrap {
		flex-shrink: 0;
		width: 355px;
		@include d {
			width: 320px; }
		@include m {
			width: 100%; } }
	.stage {
		margin-bottom: 12px; }
	&__title {
		margin-bottom: 24px; }
	&__text {
		margin-bottom: 32px;
		color: $gray; }
	&__bg {
		position: absolute;
		transform: translateY(-50%);
		pointer-events: none;
		@include m {
			position: relative;
			transform: translateX(0); } }
	&__preview {
		img {
			width: 100%; }
		@include nf {
			position: absolute; } }
	@include nl {
		margin-bottom: 134px;
		@include d {
			margin-bottom: 112px; }
		@include m {
			margin-bottom: 72px; } } }
