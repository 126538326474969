.pricing {
	&__center {
		@include m {
			padding: 0 16px; } }
	&__title {
		margin-bottom: 16px;
		text-align: center;
		@include m {
			max-width: 295px;
			margin: 0 auto 16px; } }
	&__info {
		margin-bottom: 104px;
		text-align: center;
		color: $gray;
		@include d {
			margin-bottom: 88px; }
		@include m {
			max-width: 265px;
			margin: 0 auto 40px; } }
	&__list {
		display: flex;
		padding-bottom: 36px;
		border-radius: 24px;
		background: rgba(#F7F7F8, .8);
		@include m {
			display: block;
			padding: 0;
			background: none; } }
	&__item {
		position: relative;
		display: flex;
		flex-direction: column;
		flex: 0 0 33.333%;
		width: 33.333%;
		@include m {
			width: 100%;
			padding: 24px;
			border-radius: 24px;
			background: #F7F7F8; }
		&:nth-child(2) {
			background: $white;
			border-radius: 24px;
			@include m {
				background: #F7F7F8; } }
		@include nl {
			@include m {
				margin-bottom: 24px; } } }
	&__head {
		position: relative;
		z-index: 3;
		padding: 40px 40px 50px;
		@include d {
			padding: 40px 24px 50px; }
		@include m {
			margin-bottom: 32px;
			padding: 0; } }
	&__item:nth-child(2) &__head {
		&:before {
			content: "";
			position: absolute;
			top: -32px;
			left: 0;
			right: 0;
			bottom: -32px;
			z-index: -1;
			background: $white;
			box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
			border-radius: 24px;
			@include m {
				display: none; } } }
	&__item:not(:nth-child(2)) &__head {
		box-shadow: inset 0 -1px 0 $light;
		@include m {
			box-shadow: none; } }
	&__note {
		position: absolute;
		top: -24px;
		right: 8px;
		display: inline-block;
		padding: 8px 16px;
		border-radius: 16px;
		background: $secondary;
		@include chip-sm;
		color: $white;
		@include m {
			position: static;
			margin-bottom: 20px; } }
	&__subtitle {
		margin-bottom: 24px;
		@include h5; }
	&__price {
		position: relative;
		display: flex;
		height: 110px;
		margin-bottom: 24px;
		padding-bottom: 36px;
		border-bottom: 2px solid rgba($secondary, .1); }
	&__img {
		position: absolute;
		top: 50%;
		left: -63px;
		transform: translateY(-60%); }
	&__currency {
		position: relative;
		top: 4px;
		@include h5; }
	&__number {
		@include d2; }
	&__time {
		position: relative;
		top: -7px;
		align-self: flex-end;
		color: $gray; }
	&__text {
		max-width: 200px;
		min-height: 72px;
		margin-bottom: 12px;
		color: $gray;
		@include m {
			min-height: auto;
			margin-bottom: 24px; } }
	&__details {
		position: relative;
		padding: 130px 40px 65px;
		@include d {
			padding: 130px 24px 65px; }
		@include m {
			padding: 0; } }
	&__stage {
		position: absolute;
		top: 70px;
		left: 40px;
		right: 40px;
		@include h5;
		@include d {
			left: 24px;
			right: 24px; }
		@include m {
			display: none; } }
	&__category {
		margin-bottom: 43px;
		padding-bottom: 35px;
		border-bottom: 2px solid rgba($secondary, .2);
		@include h6;
		color: $gray;
		@include m {
			display: none; } }
	&__item:first-child &__category {
		margin-right: -40px;
		padding-right: 40px;
		@include d {
			margin-right: -24px;
			padding-right: 24px; } }
	&__item:nth-child(2) &__category {
		margin-left: -40px;
		margin-right: -40px;
		padding-left: 40px;
		padding-right: 40px;
		@include d {
			margin-left: -24px;
			margin-right: -24px;
			padding-left: 24px;
			padding-right: 24px; } }
	&__item:last-child &__category {
		margin-left: -40px;
		padding-left: 40px;
		@include d {
			margin-left: -24px;
			padding-left: 24px; } }
	&__view {
		display: none;
		align-items: center;
		font-weight: 600;
		color: $black;
		@include m {
			display: inline-flex; }
		.icon {
			margin-left: 10px;
			width: 16px;
			height: 10px;
			transition: transform .2s; }
		&.active .icon {
			transform: rotate(180deg); } }
	&__options {
		@include m {
			display: none;
			padding-top: 32px; }
		li {
			position: relative;
			padding-left: 36px;
			color: $gray;
			&:before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 24px;
				height: 24px;
				background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Ccircle cx='12' cy='12' r='12' fill='%23748fb5'/%3E%3Cg clip-path='url(%23A)'%3E%3Cpath d='M18.3 6.3L9 15.6l-3.3-3.3c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l4 4c.2.2.4.3.7.3s.5-.1.7-.3l10-10c.4-.4.4-1 0-1.4s-1-.4-1.4 0z' fill='%23fff'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='A'%3E%3Cpath fill='%23fff' transform='translate(4 4)' d='M0 0h16v16H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E") no-repeat 0 0 / 100% auto; }
			@include nl {
				margin-bottom: 32px;
				@include d {
					margin-bottom: 24px; } } } }
	@include nl {
		margin-bottom: 88px;
		@include d {
			margin-bottom: 80px; }
		@include m {
			margin-bottom: 40px; } } }

@include dark {
	.pricing {
		&__list {
			background: rgba(#283036, .8); }
		&__item {
			@include m {
				background: $dark-bg; }
			&:nth-child(2) {
				background: #2C353D;
				box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1); } }
		&__item:not(:nth-child(2)) .pricing__head {
			box-shadow: inset 0 -1px 0 rgba($white, .05);
			@include m {
				box-shadow: none; } }
		&__item:nth-child(2) .pricing__head {
			&:before {
				background: $dark-bg; } }
		&__price {
			border-color: rgba($white, .1); }
		&__view {
			color: $white;
			.icon {
				fill: $white; } } } }

