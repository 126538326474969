.review {
	text-align: center;
	&__title {
		max-width: 370px;
		margin: 0 auto 80px;
		@include d {
			margin-bottom: 64px; }
		@include t {
			margin-bottom: 48px; }
		@include m {
			margin-bottom: 40px; } }
	&__container {
		margin: 0 -32px;
		@include t {
			margin: 0 -16px; } }
	&__item {
		display: flex;
		flex-direction: column;
		height: 100%;
		margin: 0 32px;
		@include t {
			margin: 0 16px; } }
	&__text {
		flex-grow: 1;
		margin-bottom: 32px;
		@include h5;
		@include d {
			font-size: 20px;
			line-height: 1.2; }
		@include t {
			font-size: 18px;
			line-height: 1.3; } }
	&__rating {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 32px; }
	&__icon {
		@include nl {
			margin-right: 16px; } }
	&__author {
		margin-bottom: 4px;
		@include title; }
	&__company {
		color: #757575; }
	.owl-stage {
		display: flex; }
	.owl-carousel .owl-item img {
		width: auto; }
	.owl-nav {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 116px;
		@include x {
			margin-top: 80px; }
		@include t {
			margin-top: 64px; }
		@include m {
			margin-top: 48px; } }
	.owl-carousel .owl-nav button.owl-prev,
	.owl-carousel .owl-nav button.owl-next {
		flex-shrink: 0;
		width: 40px;
		height: 40px;
		font-size: 0;
		path {
			font-size: 16px;
			fill: $gray;
			transition: fill .2s; }
		&:hover path {
			fill: $black; } }
	.owl-carousel .owl-nav button.owl-prev {
		margin-right: 24px; }
	@include nl {
		margin-bottom: 136px;
		@include d {
			margin-bottom: 112px; }
		@include m {
			margin-bottom: 48px; } } }

@include dark {
	.review {
		.owl-carousel .owl-nav button.owl-prev,
		.owl-carousel .owl-nav button.owl-next {
			&:hover path {
				fill: $white; } } } }

