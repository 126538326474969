.popup {
	max-width: 548px;
	margin: 0 auto;
	padding: 40px 46px 56px;
	background: #283036;
	border: 2px solid rgba(255, 255, 255, 0.1);
	box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.25);
	border-radius: 32px;
	text-align: center;
	color: $white;
	@include m {
		padding: 32px 32px 40px;
		border-radius: 24px; }
	&__title {
		margin-bottom: 46px; }
	&__variants {
		display: flex;
		margin: 0 -8px 40px;
		@include m {
			display: block;
			margin: 0 0 32px; } }
	&__variant {
		display: flex;
		justify-content: center;
		align-items: center;
		flex: 0 0 calc(50% - 16px);
		width: calc(50% - 16px);
		height: 64px;
		margin: 0 8px;
		border-radius: 16px;
		border: 2px solid rgba($white, .2);
		font-weight: 700;
		color: $white;
		transition: all .2s;
		@include m {
			width: 100%;
			margin: 0; }
		img {
			max-height: 24px;
			margin-right: 10px; }
		&:hover {
			background: rgba($white, .2);
			border-color: transparent; }
		@include nl {
			@include m {
				margin-bottom: 16px; } } }
	&__details {
		margin-bottom: 24px;
		color: $gray-dark; }
	&__btns {
		@include nl {
			margin-bottom: 24px; } }
	&__btns &__btn {
		min-width: 100%;
		@include nl {
			margin-bottom: 20px;
			@include m {
				margin-bottom: 16px; } } }
	&__note {
		max-width: 340px;
		margin: 0 auto;
		font-size: 13px;
		line-height: (24/13);
		color: $gray; }
	&__info {
		margin-bottom: 48px;
		color: $gray-dark;
		@include m {
			margin-bottom: 24px; }
		a {
			border-bottom: 1px solid transparent;
			color: $white;
			transition: border-color .2s;
			&:hover {
				border-color: $white; } } }
	&__code {
		display: flex;
		margin: 0 -8px 20px;
		@include m {
			margin: 0 -4px 20px; } }
	&__number {
		flex: 0 0 calc((100%/6) - 16px);
		width: calc((100%/6) - 16px);
		margin: 0 8px;
		@include m {
			flex: 0 0 calc((100%/6) - 8px);
			width: calc((100%/6) - 8px);
			margin: 0 4px; }
		input {
			width: 100%;
			height: 77px;
			border-radius: 8px;
			background: #35414B;
			border: 1px solid transparent;
			text-align: center;
			font-size: 40px;
			font-weight: 500;
			color: $primary;
			@include m {
				height: 56px;
				font-size: 32px; }
			&:focus {
				border-color: $white; } } }
	&__link {
		font-weight: 700;
		color: $gray;
		transition: color .2s;
		&:hover {
			color: $white; } }
	.mfp-close {
		position: absolute;
		top: 12px;
		right: 12px;
		width: 48px;
		height: 48px;
		border-radius: 50%;
		line-height: normal;
		opacity: 1;
		font-size: 0;
		background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'%3E%3Cpath fill='%23ffffff' d='M1.613.2l.094.083L5 3.585 8.293.293a1 1 0 0 1 1.414 0 1 1 0 0 1 .083 1.32l-.083.094L6.415 5l3.292 3.293a1 1 0 0 1-1.32 1.497l-.094-.083L5 6.415 1.707 9.707A1 1 0 0 1 .21 8.387l.083-.094L3.585 5 .293 1.707a1 1 0 0 1 0-1.414A1 1 0 0 1 1.613.21z'/%3E%3C/svg%3E") no-repeat 50% 50% / 14px auto;
		transition: box-shadow .25s;
		-webkit-tap-highlight-color: rgba(0,0,0,0);
		@include m {
			width: 40px;
			height: 40px; }
		&:hover {
			box-shadow: 0 5px 20px rgba(#000, .15); } } }


.mfp-container {
	padding: 20px;
	@include m {
		padding: 0; } }

@media (max-width: 900px) {
	.mfp-container {
		padding: 30px 20px; } }

.mfp-bg {
	background: rgba($dark, .98); }

.mfp-zoom-in {
	.popup {
		opacity: 0;
		transition: all 0.2s ease-in-out;
		transform: scale(0.8); }
	&.mfp-bg {
		opacity: 0;
		transition: all 0.2s ease-out; }
	&.mfp-ready {
		.popup {
			opacity: 1;
			transform: scale(1); }
		&.mfp-bg {
			opacity: 0.98; } }
	&.mfp-removing {
		.popup {
			transform: scale(0.8);
			opacity: 0; }
		&.mfp-bg {
			opacity: 0; } } }

