.history {
	position: relative;
	&__center {
		@include d {
			padding: 0 32px; }
		@include m {
			padding: 0 12px; } }
	&__container {
		position: relative;
		display: flex;
		align-items: center;
		min-height: 590px;
		padding: 40px 80px;
		border-radius: 24px;
		box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.1);
		background: #F7F7F8;
		@include d {
			min-height: 550px;
			padding: 40px 64px; }
		@include t {
			min-height: 500px;
			padding: 40px 32px; }
		@include m {
			padding: 320px 24px 42px; }
		&:before {
			content: "";
			position: absolute;
			top: 16px;
			left: 16px;
			right: 16px;
			bottom: -16px;
			z-index: -1;
			background: rgba($white, .3);
			border-radius: 24px;
			@include m {
				top: 8px;
				left: 8px;
				right: 8px;
				bottom: -8px; } } }
	&__wrap {
		position: relative;
		z-index: 3;
		max-width: 400px;
		@include d {
			max-width: 380px; }
		@include t {
			max-width: 330px; }
		@include m {
			max-width: 100%; } }
	.stage {
		margin-bottom: 16px; }
	&__title {
		margin-bottom: 24px; }
	&__info {
		margin-bottom: 24px;
		color: $gray; }
	&__bg {
		position: absolute;
		top: 50.5%;
		right: calc(50% - 789px);
		transform: translateY(-50%);
		pointer-events: none;
		@include d {
			right: calc(50% - 620px);
			width: 750px; }
		@include t {
			right: calc(50% - 450px);
			width: 570px; }
		@include m {
			top: 55px;
			right: 44%;
			transform: translateX(50%);
			width: 370px; } }
	&__preview {
		&:nth-child(1) {
			width: 90%; }
		&:nth-child(2) {
			top: -14.5%;
			left: 9.5%;
			width: 34%;
			&.aos-animate {
				animation: up-down 4s 1.2s infinite; } }
		&:nth-child(3) {
			top: 14%;
			right: 17%;
			width: 11%;
			&.aos-animate {
				animation: up-down 4s 1.8s infinite; } }
		&:nth-child(4) {
			bottom: -7%;
			left: 21%;
			width: 17%; }
		&:nth-child(5) {
			bottom: 13%;
			left: -1%;
			width: 18%; }
		img {
			width: 100%; }
		@include nf {
			position: absolute; } }
	@include nl {
		margin-bottom: 136px;
		@include d {
			margin-bottom: 112px; }
		@include m {
			margin-bottom: 96px; } } }

@include dark {
	.history {
		&__container {
			background: #283036;
			overflow: hidden;
			&:before {
				background: rgba($white, .1); }
			&:after {
				content: "";
				position: absolute;
				top: -112px;
				left: -101px;
				width: 389px;
				height: 389px;
				border-radius: 50%;
				background: linear-gradient(135.15deg, #1d3557 1.17%, #1d3557 31.88%, #7399b0 65.46%);
				filter: blur(160px); } } } }
