.field {
	&__label {
		margin-bottom: 16px;
		color: $gray-dark; }
	&__input,
	&__textarea {
		width: 100%;
		border-radius: 16px;
		background: $white;
		border: none;
		@include fi;
		font-size: 16px;
		font-weight: 600;
		color: $black;
		@include placeholder {
			color: $gray; } }
	&__input {
		height: 64px;
		padding: 0 24px; }
	&__textarea {
		height: 160px;
		padding: 20px 24px;
		resize: none; } }

@include dark {
	.field {
		&__input,
		&__textarea {
			background: #283036;
			color: $white; } } }
