.share {
	&__center {
		@include d {
			padding: 0 32px; }
		@include m {
			padding: 0 12px; } }
	&__container {
		position: relative;
		padding: 96px 140px;
		color: $white;
		@include d {
			padding: 96px 80px; }
		@include t {
			padding: 64px 40px; }
		@include m {
			padding: 40px 32px; } }
	&__bg {
		@include coverdiv;
		z-index: -1;
		overflow: hidden;
		background: $dark-bg;
		border-radius: 24px;
		&:before,
		&:after {
			content: "";
			position: absolute;
			border-radius: 50%;
			background: linear-gradient(135.15deg, #1d3557 1.17%, #1d3557 31.88%, #7399b0 65.46%); }
		&:before {
			top: -280px;
			left: -280px;
			width: 560px;
			height: 560px;
			opacity: 0.6;
			filter: blur(90px); }
		&:after {
			right: -195px;
			bottom: -181px;
			width: 389px;
			height: 389px;
			filter: blur(160px);
			opacity: 0.6;
			@include m {
				display: none; } } }
	&__row {
		position: relative;
		z-index: 3;
		display: flex;
		margin: 0 -120px;
		@include d {
			margin: 0 -80px; }
		@include t {
			margin: 0 -40px; }
		@include m {
			display: block;
			margin: 0; } }
	&__col {
		flex: 0 0 50%;
		width: 50%;
		padding: 0 120px;
		@include d {
			padding: 0 80px; }
		@include t {
			padding: 0 40px; }
		@include m {
			width: 100%;
			padding: 0; }
		&:first-child {
			position: relative;
			&:before {
				content: "";
				position: absolute;
				top: 0;
				right: -2px;
				bottom: 0;
				width: 4px;
				background: rgba($secondary, .1);
				@include m {
					display: none; } } }
		@include nl {
			@include m {
				margin-bottom: 48px; } } }
	&__preview {
		margin-left: -140px; }
	&__title {
		margin-bottom: 12px;
		@include m {
			max-width: 240px;
			font-size: 24px; } }
	&__text {
		max-width: 260px;
		margin-bottom: 40px;
		color: $gray;
		@include m {
			margin-bottom: 24px; } }
	.btn_border {
		box-shadow: inset 0 0 0 2px $white;
		color: $white;
		&:hover {
			box-shadow: none;
			background: $white;
			color: $black; } }
	@include nl {
		margin-bottom: 136px;
		@include d {
			margin-bottom: 96px; }
		@include m {
			margin-bottom: 48px; } } }

