.switch {
	position: fixed;
	left: calc(50% - 700px);
	bottom: 30px;
	display: inline-block;
	user-select: none;
	cursor: pointer;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	font-size: 0;
	transition: opacity .25s;
	@include w {
		left: 32px; }
	@include x {
		top: 72px;
		right: 64px;
		left: auto;
		bottom: auto; }
	@include t {
		right: 32px; }
	@include m {
		top: auto;
		right: auto;
		left: 12px;
		bottom: 8px; }
	&__input {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0; }
	&__in {
		position: relative;
		display: inline-block;
		border: 8px solid $white;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
		border-radius: 24px;
		transition: all .2s; }
	&__box {
		position: relative;
		display: block;
		width: 72px;
		height: 32px;
		box-shadow: inset 0 0 0 1px rgba($gray-light, .4);
		background: $white;
		border-radius: 16px;
		transition: all .2s;
		&:before {
			content: "";
			position: absolute;
			top: 50%;
			left: 4px;
			transform: translateY(-50%);
			width: 24px;
			height: 24px;
			border-radius: 50%;
			background: $primary;
			box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
			transition: all .2s; } }
	&__icon {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		font-size: 0;
		transition: opacity .2s;
		.icon {
			width: 24px;
			height: 24px;
			fill: #808191; }
		&_light {
			right: 5px; }
		&_dark {
			left: 4px;
			opacity: 0; } }
	&__input:checked + &__in {
		border-color: rgba(#000, .5); }
	&__input:checked + &__in &__box {
		box-shadow: inset 0 0 0 1px rgba($white, .1);
		background: rgba(#000, .3); }
	&__input:checked + &__in &__box:before {
		width: 8px;
		height: 24px;
		transform: translate(48px,-50%);
		border-radius: 4px; }
	&__input:checked + &__in &__icon_light {
		opacity: 0; }
	&__input:checked + &__in &__icon_dark {
		opacity: 1; }
	@include hover {
		opacity: .8; } }

