.d1,
.d2,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	@include fp;
	font-weight: 600; }

.d1 {
	font-size: 72px;
	line-height: (80/72);
	@include d {
		font-size: 48px;
		line-height: (56/48); }
	@include m {
		font-size: 40px;
		line-height: (48/40); } }

.d2 {
	font-size: 64px;
	line-height: (72/64); }

.h1 {
	font-size: 56px;
	line-height: (64/56); }

.h2 {
	font-size: 48px;
	line-height: (56/48);
	@include d {
		font-size: 40px;
		line-height: (48/40); }
	@include m {
		font-size: 32px;
		line-height: (40/32); } }

.h3 {
	font-size: 40px;
	line-height: (48/40); }

.h4 {
	font-size: 32px;
	line-height: (40/32); }

.h5 {
	font-size: 24px;
	line-height: (32/24); }

.h6 {
	font-size: 20px;
	line-height: (24/20); }

.stage {
	margin-bottom: 24px;
	font-size: 12px;
	font-weight: 900;
	letter-spacing: .1em;
	line-height: (16/12);
	text-transform: uppercase;
	color: $secondary;
	@include m {
		margin-bottom: 16px; } }

.color {
	 &-blue {
		 color: blue; } }

.bg {
	&-blue {
		background: blue;
		&-opacity {
			background: rgba(blue, .3); } } }

.text {
	&-right {
		text-align: right; }
	&-center {
		text-align: center; }
	&-uppercase {
		text-transform: uppercase; } }

.m-auto {
	margin: auto; }
.ml-auto {
	margin-left: auto; }
.mr-auto {
	margin-right: auto; }

.align {
	&-baseline {
		align-items: baseline; } }

.desktop {
	&-hide {
		@include d {
			display: none !important; } }
	&-show {
		display: none !important;
		@include d {
			display: block !important; } }
	&-text-right {
		@include d {
			text-align: right !important; } } }

.tablet {
	&-hide {
		@include t {
			display: none !important; } }
	&-show {
		display: none !important;
		@include t {
			display: block !important; } }
	&-text-right {
		@include t {
			text-align: right !important; } } }

.mobile {
	&-hide {
		@include m {
			display: none !important; } }
	&-show {
		display: none !important;
		@include m {
			display: block !important; } }
	&-text-right {
		@include m {
			text-align: right !important; } } }
